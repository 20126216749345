export default {
  pages: {
    header: {
      menu_1: 'Home Page',
      menu_2: 'About Us',
      subMenu_2a: 'Company Overview',
      subMenu_2b: 'Company Architecture',
      subMenu_2c: 'Milestone',
      menu_3: 'Company Announcement',
      subMenu_3a: 'Industry Layout',
      subMenu_3b: 'Product Description',
      menu_4: 'Investor Relations',
      subMenu_4a: 'Financial Information',
      subMenu_4b: 'Performance Presentation Materials',
      subMenu_4c: 'Company Announcement',
      menu_5: 'Press Center',
      subMenu_5a: 'Company News',
      menu_6: 'CN / EN',
      subMenu_6a: 'English',
      subMenu_6b: '简体中文',
    },

    about: {
      title: 'Company Overview',
      companyInfo: {
        item1: 'China Sanjiang Fine Chemical Co., Ltd.',
        item2: '(「',
        item3: 'China Sanjiang Chemical',
        item4:
          "」, stock code: 2198.HK) was listed on the Hong Kong Stock Exchange in 2010 and is China's largest private company",
        item5: 'Ethylene oxide (',
        item6: 'EO',
        item7: ') and',
        item8: 'AEO',
        item9: 'Surfactant',
        item10: 'Manufacturers and suppliers. At present, it has been formed with',
        item11: 'EO',
        item12: ', ethylene glycol (',
        item13: 'EG',
        item14: ') and polypropylene (',
        item15: 'PP',
        item16: ') is the core,',
        item17: 'AEO',
        item18: 'Surfactant, methyl tert-butyl ether (',
        item19: 'MTBE',
        item20: '), carbon five (',
        item21: 'C5',
        item22: ')',
        item23:
          'Supplied by a diversified product portfolio, it has become a vertically integrated chemical group with the ability to balance risks and resist cycles.',
      },
    },

    achieve: {
      title: 'Performance Presentation Materials',
      interimResults: 'Interim Results',
      annualPerformance: 'Annual Performance',
    },

    active: {
      title: 'Company News',
      news2021: {
        newsDate_1: 'June 07, 2021',
        newsTitle_1:
          'Sanjiang Chemical New Welfare | The new bus is put into use, and the delicious clean vegetables are packaged home',
        newsDate_2: 'June 07, 2021',
        newsTitle_2:
          'Good news!| The chorus of Sanjiang Jiahua Group won the gold medal on behalf of the Chemical Industry Park in the Jiaxing Port Mass Chorus Competition to celebrate the 100th anniversary of the founding of the Communist Party of China!',
        newsDate_3: 'June 07, 2021',
        newsTitle_3:
          'Monthly Special Issue of Work Safety | Ten Principles of Work Safety, Ten Zero Tolerance of Safety and Environmental Protection',
        newsDate_4: 'May 27, 2021',
        newsTitle_4:
          "Comprehensive utilization of waste heat for power generation | The waste heat power generation project of Sanjiang Chemical's 1 million tons/year EO/EG project supporting project started smoothly",
        newsDate_5: 'May 27, 2021',
        newsTitle_5: 'Fundamental Knowledge Training on Sanjiang Chemical Production Plant',
        newsDate_6: 'May 12, 2021',
        newsTitle_6:
          'Yu Xin, member of the Standing Committee of the Jiaxing Municipal Party Committee and the Political Commissar of the Military District, and his entourage visited Sanjiang Chemical to investigate and service enterprises',
        newsDate_7: 'April 30, 2021',
        newsTitle_7:
          'Jiaxing City\'s "Mutual Learning and Comparing Competition" activity went into Sanjiang Chemical\'s 1 million tons/year EO/EG project',
        newsDate_8: 'April 30, 2021',
        newsTitle_8:
          'Sanjiang Chemical Industry Trade Union held to celebrate the "May 1st" Labor Day and the 2020 Outstanding Trade Union Activist Commendation',
        newsDate_9: 'April 30, 2021',
        newsTitle_9:
          'A tribute to the laborers striving for a new era丨A holiday congratulations to all the employees who are fighting on the front line',
        newsDate_10: 'April 30, 2021',
        newsTitle_10:
          'Follow the Party · Celebrate the 100th Anniversary of the Founding of the Party │ Xingxing Meifu Branch to be a good example, take responsibility, and unite knowledge and action',
        newsDate_11: 'April 28, 2021',
        newsTitle_11:
          'Thinking, Improving, Breaking Through, Incorporating | Sanjiang Chemical held the first quarter work meeting and the first quarter party committee work meeting',
        newsDate_12: 'April 28, 2021',
        newsTitle_12: 'Sanjiang Chemical | Integrated strong chain supplementary chain',
        newsDate_13: 'April 28, 2021',
        newsTitle_13:
          'Learning Party History, Comprehending Thoughts, and Become a Vanguard | Sanjiang Party Branch Organizes Party History Learning Activities',
        newsDate_14: 'April 19, 2021',
        newsTitle_14:
          'Set an example and be a pioneer | The second shift of the oxygen plant operation was awarded the honorary title of "Worker Pioneer" in Jiaxing Port',
        newsDate_15: 'April 19, 2021',
        newsTitle_15:
          "Heart's Cohesion Competition | Sanjiang Chemical Industry's 14th Staff Tug-of-War Competition",
        newsDate_16: 'April 19, 2021',
        newsTitle_16: 'China CDC: Questions and Answers on New Coronavirus Vaccination',
        newsDate_17: 'March 08, 2021',
        newsTitle_17: "Dating for Spring | Happy Women's Day to you dearest",
        newsDate_18: 'March 02, 2021',
        newsTitle_18:
          'Chen Lizhong, member of the Standing Committee of the Jiaxing Municipal Party Committee and Executive Deputy Mayor, and other leaders visited the company for investigation',
        newsDate_19: 'February 26, 2021',
        newsTitle_19:
          'The Lantern Festival is fascinating for the Lantern Festival. Fun lantern riddles to celebrate the festival | Sanjiang Chemical wishes you a happy Lantern Festival',
        newsDate_20: 'February 26, 2021',
        newsTitle_20:
          'Sign a strategic cooperation agreement | Cong Jun, President of China Minsheng Bank Hangzhou Branch, visited the company for investigation',
        newsDate_21: 'February 20, 2021',
        newsTitle_21:
          'Congratulations to Ms. Han Jianhong, Chairman of the company, for winning the title of Outstanding Entrepreneur of the Year 2020',
        newsDate_22: 'February 11, 2021',
        newsTitle_22: 'Sanjiang Chemical wishes all employees a happy Chinese New Year and good health!',
        newsDate_23: 'February 10, 2021',
        newsTitle_23:
          'The most beautiful Sanjiang people pay tribute to the workers | Sanjiang Chemical holds the 2020 annual commendation meeting',
        newsDate_24: 'February 10, 2021',
        newsTitle_24:
          'Consolation to the needy employees, passing on the warmth of the union | The union launches a symposium for the needy employees',
        newsDate_25: 'February 10, 2021',
        newsTitle_25:
          'Send condolences before the holiday to warm the hearts of the Spring Festival | Party branch visits and sympathizes with the needy people',
        newsDate_26: 'January 29, 2021',
        newsTitle_26:
          'The small canteen is warm and popular, and everyone praises it | The renovation of the Sanjiang Canteen has been successfully completed and will open for trial operation on February 1st',
        newsDate_27: 'January 26, 2021',
        newsTitle_27:
          'Feng Zhonghai, Director of the Management Committee of Jiaxing Port District and other leaders visited the company to carry out the "Three Services" activities',
        newsDate_28: 'January 12, 2021',
        newsTitle_28:
          'Sanjiang Chemical Xingxing New Energy｜Bookmark of 2021 Safety and Environmental Responsibility',
        newsDate_29: 'January 05, 2021',
        newsTitle_29:
          'Work hard, continue to struggle, and be the respondent of 2021 | Sanjiang Chemical Co., Ltd. solemnly held the signing ceremony of the 2021 policy and goals',
      },
      news2020: {
        newsDate_1: 'December 16, 2020',
        newsTitle_1:
          'Sanjiang Jiahua Group Party Committee Fourth Quarter 2020 Party Committee Meeting | Focus on learning the spirit of the Fifth Plenary Session of the 19th Central Committee',
        newsDate_2: 'December 16, 2020',
        newsTitle_2: 'Trash classification, start with me',
        newsDate_3: 'December 04, 2020',
        newsTitle_3: 'All business divisions hold 2021-2023 work ideas and planning meetings',
        newsDate_4: 'December 04, 2020',
        newsTitle_4: 'Prevent telecom fraud and combat cybercrime',
        newsDate_5: 'November 25, 2020',
        newsTitle_5:
          'Safety inspection, we are taking action to learn the lessons of the 11.9 incident in Quzhou | The chairman of the company led a team to conduct a major safety inspection on Sanjiang Haojia',
        newsDate_6: 'November 25, 2020',
        newsTitle_6:
          'Creating a better life together | Sanjiang Chemical holds "Civil Code" special training',
        newsDate_7: 'November 25, 2020',
        newsTitle_7: 'Health 365 | A healthy lifestyle starts with split meals',
        newsDate_8: 'November 17, 2020',
        newsTitle_8:
          'Congratulations to the employees of the company for their good results in the provincial vocational skill competition',
        newsDate_9: 'November 17, 2020',
        newsTitle_9:
          'Leaders from the Ministry of Industry and Information Technology visited the company for research and guidance',
        newsDate_10: 'November 17, 2020',
        newsTitle_10:
          'Red Boat Party Lesson Study Record | Start a new journey of building a socialist modern country in an all-round way',
        newsDate_11: 'October 28, 2020',
        newsTitle_11:
          'Promote management innovation!|The company holds the third quarter economic work conference',
        newsDate_12: 'October 28, 2020',
        newsTitle_12: 'Prevent fire risks and strictly investigate fire hazards',
        newsDate_13: 'October 28, 2020',
        newsTitle_13: "The epidemic is not over, don't let your protection go",
        newsDate_14: 'September 30, 2020',
        newsTitle_14:
          'Sanjiang Chemical wishes all employees a happy Mid-Autumn Festival and National Day!',
        newsDate_15: 'September 30, 2020',
        newsTitle_15:
          "Visit and condolences to the needy households, a little bit of true love warms the hearts of the people | Sanjiang Chemical's party branch of the Mid-Autumn Festival and National Day condolences to the needy families",
        newsDate_16: 'September 2020 30th',
        newsTitle_16:
          "Safety is not slack, and the alarm bell should ring forever | Sanjiang Jiahua Group's safety and environmental protection pre-holiday inspection",
        newsDate_17: 'September 28, 2020',
        newsTitle_17:
          'Bringing the wind and breaking the waves, the dream will finally be expected! | Sanjiang Jiahua holds a Mid-Autumn Festival and National Day celebration party for newly recruited college students',
        newsDate_18: 'September 18, 2020',
        newsTitle_18:
          'Sanjiang polymer polyether project and the signing ceremony of the AP gas supply agreement in the United States are grandly held!',
        newsDate_19: 'August 21, 2020',
        newsTitle_19:
          'Send cool and cool to help production丨The company leaders condolences the employees who are fighting on the front line of production during the high temperature',
        newsDate_20: 'August 21, 2020',
        newsTitle_20: 'Stick to work and not leave the post, grasp the quality and not relax',
        newsDate_21: 'August 21, 2020',
        newsTitle_21: 'Keep the original heart and take on the mission, find the gap and implement it',
        newsDate_22: 'July 22, 2020',
        newsTitle_22:
          'Sanjiang Xingxing Party Branch Theme Party Day Activities | South Lake Tour to Explore the Source of Power',
        newsDate_23: 'July 22, 2020',
        newsTitle_23a: 'Jingchu Project',
        newsTitle_23b: 'The first batch of college students have officially registered!',
        newsDate_24: 'July 22, 2020',
        newsTitle_24:
          'Actively participate in the competition of learning to become a powerful country, and strive to improve the level of learning to become a powerful country',
        newsDate_25: 'July 10, 2020',
        newsTitle_25:
          'Congratulations to Guan Jianzhong, Chairman of the Board of Directors of Sanjiang Jiahua Group, for winning the honorary titles of "Good Man and "Red Ship Pioneer" Communist Party in Jiaxing',
        newsDate_26: 'July 10, 2020',
        newsTitle_26:
          'Always follow the party! | Sanjiang Jiahua held a symposium to celebrate the 99th anniversary of the founding of the Communist Party of China and party building work',
        newsDate_27: 'July 10, 2020',
        newsTitle_27:
          'Energy-saving, emission-reduction and green development 丨 Sanjiang Chemical Division launches energy-saving publicity week activities',
        newsDate_28: 'June 30, 2020',
        newsTitle_28:
          'Warmly celebrate the 99th anniversary of the founding of the Communist Party of China!',
        newsDate_29: 'June 30, 2020',
        newsTitle_29:
          'Safety Production Month丨Adhere to the principles of practical learning, practice and practice, and strengthen job safety skills training',
        newsDate_30: 'June 30, 2020',
        newsTitle_30:
          'Congratulations to Guan Jianzhong, Secretary of the Sanjiang Jiahua Party Committee, for winning the honorary title of Advanced Individual in the National Petroleum and Chemical Industry Epidemic Prevention and Control Work!',
        newsDate_31: 'June 30, 2020',
        newsTitle_31:
          'Safety Production Month丨Adhere to the principles of practical learning and practicality, and strengthen job safety skills training',
        newsDate_32: 'June 24, 2020',
        newsTitle_32:
          'Feng Zhonghai, Director of the Management Committee of Jiaxing Port District, and his entourage visited Sanjiang Jiahua to investigate and service enterprises',
        newsDate_33: 'June 24, 2020',
        newsTitle_33: 'Safety Production Month | Carry out safety training and improve safety awareness',
        newsDate_34: 'June 19, 2020',
        newsTitle_34:
          'Sanjiang·Jiahua and Jiaxing College signed a cooperation framework agreement for the establishment of the Future Technical College',
        newsDate_35: 'June 19, 2020',
        newsTitle_35:
          'Safety Production Month丨Strictly investigate potential safety hazards and consolidate safety responsibilities',
        newsDate_36: 'June 19, 2020',
        newsTitle_36:
          'Emergency Science Popularization | Learn how to deal with the leakage of hazardous chemicals and master self-rescue methods',
        newsDate_37: 'June 09, 2020',
        newsTitle_37: 'Safety Production Month 2020',
        newsDate_38: 'June 09, 2020',
        newsTitle_38: '2020 "Sanjiang·Jiahua" Fire Fighting Emergency Skills Competition',
        newsDate_39: 'June 09, 2020',
        newsTitle_39:
          'Use of emergency equipment: fire extinguishers, fire hydrants, positive pressure breathing apparatus, all employees must see!',
        newsDate_40: 'April 30, 2020',
        newsTitle_40:
          "Warm congratulations to the company's general manager Guan Jianzhong for winning the 2020 Jiaxing May 1st Labor Medal and other honors",
        newsDate_41: 'April 30, 2020',
        newsTitle_41:
          'Warmly celebrate the "May 1st" International Labor Day | Congratulations to all employees who are fighting on the front line',
        newsDate_42: 'April 30, 2020',
        newsTitle_42:
          'Sanjiang Jiahua Group is actively studying the spirit of the investigation speech of Zhang Binggang District, Secretary of the Jiaxing Municipal Party Committee',
        newsDate_43: 'April 23, 2020',
        newsTitle_43:
          'Simultaneously implement implementation, and live up to expectations! Zhang Bing, secretary of the Jiaxing Municipal Party Committee, and his entourage came to Sanjiang Jiahua for research and guidance',
        newsDate_44: 'April 15, 2020',
        newsTitle_44:
          '"Point-to-point" cloud video | Sanjiang Jiahua\'s first online interview is in full swing',
        newsDate_45: 'April 02, 2020',
        newsTitle_45:
          'Jingchu Project "Job" is waiting for you | Sanjiang Jiahua Wuhan Targeted "Cloud" Recruitment Opening',
        newsDate_46: 'March 24, 2020',
        newsTitle_46: 'Hubei employees cannot be less, Sanjiang Jiahua will pick you up and rework!',
        newsDate_47: 'March 20, 2020',
        newsTitle_47:
          'Congratulations to the general manager of our company, Mr. Guan Jianzhong, for winning the honor of "Meritable Entrepreneur" in Jiaxing City',
        newsDate_48: 'March 20, 2020',
        newsTitle_48:
          "Don't relax at critical moments and persist in the prevention and control of imported epidemics",
        newsDate_49: 'March 20, 2020',
        newsTitle_49:
          'Congratulations to Zhejiang Sanjiang New Chemical Materials Co., Ltd. for being rated as one of the top 20 petrochemical companies in Zhejiang Province with a per-mu profit margin.',
        newsDate_50: 'March 13, 2020',
        newsTitle_50:
          'Dai Feng, Secretary of the Party Working Committee of Jiaxing Port District, and his entourage came to our company to carry out the "Three Services" activities',
        newsDate_51: 'March 10, 2020',
        newsTitle_51:
          'Director Zhuo Weiming of the Jiaxing Economic and Information Bureau visited the company to investigate and serve the company',
        newsDate_52: 'February 21, 2020',
        newsTitle_52: 'Prevent and control the epidemic, return to work and stabilize production',
        newsDate_53: 'February 03, 2020',
        newsTitle_53:
          "Never let go of the epidemic prevention and control link-the chief inspector of the company's epidemic prevention and control work",
        newsDate_54: 'February 03, 2020',
        newsTitle_54: 'New Coronavirus Prevention Handbook',
        newsDate_55: 'January 23, 2020',
        newsTitle_55: 'The Chairman of the Board of Directors sent you a blessing kit',
        newsDate_56: 'January 21, 2020',
        newsTitle_56: "Sanjiang Chemical Co., Ltd. Labor Union's Spring Festival Condolences",
        newsDate_57: 'January 13, 2020',
        newsTitle_57:
          "Our company won the honor of model corporate citizenship in China's petrochemical industry in 2019",
        newsDate_58: 'January 01, 2020',
        newsTitle_58:
          "The responsibility lies in the shoulder and the mission is in the heart-Sanjiang Jiahua's 2020 policy and target signing ceremony was held",
        newsDate_59: 'January 01, 2020',
        newsTitle_59: "Sanjiang Chemical wishes all employees a happy New Year's Day!",
      },
      news2019: {
        newsDate_1: 'December 24, 2019',
        newsTitle_1:
          'Congratulations to Sanjiang Chemical and Jiaxing Taxation Bureau for signing the "Tax Compliance Cooperation Agreement"',
        newsDate_2: 'December 18, 2019',
        newsTitle_2:
          'Warm your heart in winter-the group party committee holds a democratic life meeting',
        newsDate_3: 'November 26, 2019',
        newsTitle_3: 'Challenge of the brave, there are you and me in the marathon',
        newsDate_4: 'October 29, 2019',
        newsTitle_4: 'Sanjiang Jiahua Group held a third quarter work meeting',
        newsDate_5: 'October 17, 2019',
        newsTitle_5:
          'Sanjiang Chemical Industry responds to the "Social Poverty Alleviation into Enterprises and Units" activity',
        newsDate_6: 'September 30, 2019',
        newsTitle_6: 'Sanjiang Jiahua Group Party Committee Inaugural Meeting Held Grandly',
        newsDate_7: 'September 30, 2019',
        newsTitle_7:
          'Celebrate the 70th Anniversary of Daqing and Keep Safe, Safe and Environmentally Friendly',
        newsDate_8: 'March 14, 2019',
        newsTitle_8:
          'Sanjiang Chemical Industry Labor Union launches a series of activities of "Exhibiting New Style and Becoming a New Woman" March 8th Festival',
        newsDate_9: 'March 14, 2019',
        newsTitle_9:
          'The Party Branch of Sanjiang Chemical Industry launched the "Arbor Day" theme party day activities',
        newsDate_10: 'February 19, 2019',
        newsTitle_10: 'Starting to run, honor is motivation',
        newsDate_11: 'February 19, 2019',
        newsTitle_11:
          'The labor union of Sanjiang Chemical Co., Ltd. actively carried out the "Two Festivals" in 2019 to help the poor and send warmth activities',
        newsDate_12: 'February 02, 2019',
        newsTitle_12:
          'Congratulations to Sanjiang Jiahua for winning awards such as the best enterprise cluster in the moving port area in 2018',
      },
      news2018: {
        newsDate_1: 'December 29, 2018',
        newsTitle_1: 'The leaders of Jiaxing CPPCC visited the company to guide the work',
        newsDate_2: 'December 14, 2018',
        newsTitle_2:
          "Congratulations to the chairman of the company's board of directors, Mr. Guan Jianzhong, for winning the outstanding contribution to the 40th anniversary of the reform and opening up of China's petroleum and chemical industry!",
        newsDate_3: 'September 30, 2018',
        newsTitle_3: 'Chairman Guan leads a National Day safety production inspection',
      },
    },

    first: {
      companyAnnouncement: {
        title: 'Company Announcement',
        announceTitle_1: 'Monthly Statement on Changes in Securities Issued by Shares',
        announceDate_1: 'October 04, 2021',
        announceTitle_2: '2021 Interim Report',
        announceDate_2: 'September 09, 2021',
        announceTitle_3: 'Monthly Statement of Equity Issuer on Changes in Securities',
        announceDate_3: 'August 31, 2021',
        announceTitle_4a: 'For the six months ended June 30, 2021',
        announceTitle_4b: 'Interim Results Announcement',
        announceDate_4: 'August 26, 2021',
        announceTitle_5: 'List of directors and their status and role',
        announceDate_5: 'August 26, 2021',
      },
      contactInfo: {
        title: 'Contact Sanjiang Chemical',
        hkAddress:
          'Hong Kong Address: Room 1702, Infinitus Plaza, 199 Des Voeux Road Central, Sheung Wan, Hong Kong',
        cnAddress:
          'China (Mainland) Address: West of Pinghai Road, Jiaxing Port District, Zhejiang (314201)',
        investorRelationsContact: 'Investor Relations Contact',
        contactPerson_1: 'Chen Xian',
        contactEmail_1: 'chenx@jxsjchem.com',
        contactNo_1: '(+86) 57385286836',
        contactPerson_2: 'Henry, Yip Ngai Hang',
        contactEmail_2: 'henryyip@jxsjchem.com',
        contactNo_2: '(+852) 28510255',
      },
    },

    framework: {
      title: 'Ownership Structure',
      directorResume: {
        title: "Director's Resume",
        director1a: 'Han Jianhong',
        director1b:
          'He has been an executive director since March 22, 2010. Ms. Han is also one of the founders of the group. She is mainly responsible for the business planning, business structure and restructuring of the group, and oversees the legal affairs of the group And investor relations. Ms. Han also serves as a director of several subsidiaries of the Group and has more than 20 years of experience in the chemical industry. Ms. Han is the spouse of executive director Mr. Guan Jianzhong and the sister of executive director Mr. Han Jianping.',
        director2a: 'Han Jianping',
        director2b:
          'He has been an executive director since August 24, 2010. He is mainly responsible for the sales of the group.',
        director2c:
          'Mr. Han also serves as a director of certain subsidiaries of the Group and has more than 25 years of experience in the chemical industry. Mr. Han is the elder brother of Ms. Han Jianhong, an executive director, and the brother-in-law of Mr. Guan Jianzhong, an executive director. Mr. Han in 1998 Joined the group in the year.',
        director3a: 'Rao Huotao',
        director3b:
          "Appointed as an executive director on March 15, 2017. He is mainly responsible for the project management of the Group. Mr. Rao graduated from Wuhan Institute of Technology with a bachelor's degree in chemical technology in 1996. He graduated from Zhejiang University in 2008 with a master's degree in chemical engineering. Mr. Rao has over 22 years of experience in the chemical manufacturing industry and joined the Group in 2010.",
        director4a: 'Shen Kaijun',
        director4b:
          'Since August 24, 2010, he has been an independent non-executive director. He is the chairman of the audit committee and a member of the nomination committee of the company. Mr. Shen graduated from Hangzhou Institute of Electronic Technology in 1995 with a bachelor of accounting degree Degree and was accepted as a certified public accountant in China in December 1993. Mr. Shen was further awarded the qualification of certified public accountant in July 1997 and can engage in securities-related business. Later in 2000 He was awarded the qualification of registered tax agent in June. Mr. Shen has more than 25 years of experience in accounting and corporate management.',
        director5a: 'Pei Yu',
        director5b:
          'Since June 30, 2014, he has been an independent non-executive director. She is a member of the Audit Committee, Remuneration Committee and Nomination Committee. Ms. Pei graduated from Beijing Normal University in June 1992 with a Bachelor of Arts degree Degree, and obtained a double degree from the Faculty of Law of China University of Political Science and Law in June 1997. Ms. Pei has more than 17 years of experience in the Chinese legal field.',
        director6a: 'Kong Liang',
        director6b:
          "Since July 25, 2016, he has served as an independent non-executive director. He is a member of the audit committee and the remuneration committee. Mr. Kong graduated from Fudan University in 1988 with a bachelor's degree in management science. He graduated from the University of International Business and Economics in 1991 with a master's degree in economics, graduated from the Nairrode Business University in 1995 with a master's degree in business administration, and graduated from Peking University in 2011. Received a PhD in Educational Economics and Management. Mr. Kong has more than 17 years of experience in teaching higher education in business administration.",
      },
      seniorManagementResume: {
        title: 'Resume of Senior Management',
        senior1a: 'Chen Xian',
        senior1b:
          'Secretary of the Board of Directors and Chief Accountant of the Group. Ms. Chen is mainly responsible for the financial management of the Group. Ms. Chen graduated from Hangzhou Business School of Zhejiang Gongshang University with a diploma in accounting in 1996 and obtained a diploma in accounting from the People’s Republic of China in 2013. Bachelor degree in accounting. He joined the group in 2009.',
        senior2a: 'Ding Rongguo',
        senior2b:
          'The production director of the group. Mr. Ding is mainly responsible for the production management, safety and environmental protection of the group. He joined the group in 2004.',
      },
    },

    industry: {
      title: 'Industry Layout',
    },

    mileage: {
      title: 'Milestone',
    },

    money: {
      title: 'Financial Information',
      currencyRMB: '(RMB Thousand Yuan)',
      year1: 'Mid 2019',
      year2: '2018',
      year3: '2017',
      year4: '2016',
      year5: '2015',
      moneyInfo_1: {
        title: 'Earnings',
        year1: '4,190,077',
        year2: '9,585,859',
        year3: '8,897,143',
        year4: '6,647,019',
        year5: '4,966,427',
      },
      moneyInfo_2: {
        title: 'Maori',
        year1: '206,338',
        year2: '996,124',
        year3: '1,085,275',
        year4: '906,570',
        year5: '164,449',
      },
      moneyInfo_3: {
        title: 'Net Profit Attributable to Shareholders',
        year1: '32,275',
        year2: '398,915',
        year3: '690,793',
        year4: '552,614',
        year5: '(145,502)',
      },
      moneyInfo_4: {
        title: 'Earnings per share-basic (RMB, cents)',
        year1: '3.15',
        year2: '38.89',
        year3: '67.30',
        year4: '56.09',
        year5: '(14.79)',
      },
      moneyInfo_5: {
        title: 'Final dividend per share (HK cents)',
        year1: '-',
        year2: '10.0',
        year3: '12.5',
        year4: '11.5',
        year5: '-',
      },
      moneyInfo_6: {
        title: 'Interim dividend per share (HK cents)',
        year1: '5.0',
        year2: '10.0',
        year3: '10.5',
        year4: '-',
        year5: '-',
      },
      moneyInfo_7: {
        title: 'Dividend payout ratio (based on the total amount paid during the year)',
        year1: '-',
        year2: '50.0%',
        year3: '30.0%',
        year4: '18.4%',
        year5: '-',
      },
      moneyInfo_8: {
        title: 'Gross Margin',
        year1: '4.9%',
        year2: '10.4%',
        year3: '12.2%',
        year4: '13.6%',
        year5: '3.3%',
      },
      moneyInfo_9: {
        title: 'Asset-to-liability ratio-based on interest-bearing loans to total assets',
        year1: '35.2%',
        year2: '32.6%',
        year3: '27.1%',
        year4: '42.9%',
        year5: '50.9%',
      },
    },

    notice: {
      topTitle: 'Pinned Announcement',
      topItem1: 'Dissemination of Corporate Communications',
      title: 'Company Announcement',
      announceTitle_1: 'Monthly Statement on Changes in Securities Issued by Shares',
      announceDate_1: 'October 04, 2021',
      announceTitle_2: '2021 Interim Report',
      announceDate_2: 'September 09, 2021',
      announceTitle_3: 'Monthly Statement of Equity Issuer on Changes in Securities',
      announceDate_3: 'August 31, 2021',
      announceTitle_4: 'Announcement of Interim Results for the Six Months Ended June 30, 2021',
      announceDate_4: 'August 26, 2021',
      announceTitle_5: 'List of directors and their status and role',
      announceDate_5: 'August 26, 2021',
      announceTitle_6: 'Appointment of Executive Director and Board Composition Change',
      announceDate_6: 'August 26, 2021',
      announceTitle_7: 'Announcement of the Board Meeting',
      announceDate_7: 'August 10, 2021',
      announceTitle_8: 'Monthly Statement of Equity Issuer on Changes in Securities',
      announceDate_8: 'August 01, 2021',
      announceTitle_9: 'Monthly Statement of Equity Issuer on Changes in Securities',
      announceDate_9: 'June 30, 2021',
      announceTitle_10: 'Monthly Statement of Equity Issuer on Changes in Securities',
      announceDate_10: 'June 01, 2021',
    },

    product: {
      title: 'Product Introduction',
    },
  },
};
